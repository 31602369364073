<template>

<div class="main-container">
    
  
    <ProdutoListForm v-if="isAuth"/>
    <Login  v-else/>

   
  </div>

    
</template>

<script>
import ProdutoListForm from "../components/forms/Listas/ProdutoListForm.vue" 
import Login from "../components/forms/LoginForm.vue"

export default {
    name:"UserList",
    components:{
        ProdutoListForm,
        Login
    },
    
    computed:{
      
      isAuth(){
         if (this.$store.getters.getUserID != null){
           return true
         } else {
           return false
         }

       }

    }






}
</script>

<style scoped>
  .main-container{
    margin: 50px;
    min-height: 250px;
  }
</style>

