<template>
    <div class="container">
    

        <!--

                <div class="form-row">
                    <div class="form-group col-md-12">
                       <label for="valor">Valor</label>
                       <input name="valor" maxlength="15" type="tel" v-model="valor"  class="form-control" id="valor" placeholder="Valor" pattern="([0-9]{1,3}\.)?[0-9]{1,3},[0-9]{2}$">
                                      
                    </div>
                </div>



        -->    



        <div v-show="msg" class="alert"  :class="tipoAlerta" role="alert">
            <h5>{{msg}}</h5>
        </div>

        <div>
            <h3>Produtos Prospectados</h3> 
            
        </div>

        <div class="painel-superior">

            <div v-if="tOp == 'view'">
               <button  class="novo-btn" @click="novoProduto">Novo</button>
            </div>
            <div v-else>
               <button class="novo-btn" @click="salvarProduto">Salvar</button>
               <button class="novo-btn" @click="cancelarProduto">Cancelar</button> 
            </div>

        </div>

        <div id="list-table" v-if="tOp == 'view'">
           
            <div>
                <br /><br />
               
                <div id="head-table">
                    <div class="order-id"></div>
                    <div>Descrição</div>
                   <div>Observação</div>
                    <div>Ações</div>  
                </div>
               
               
                <div id="det-rows">
                    <div class="det-row" v-for="produto in produtos" :key="produto.id">
                        <div class="order-number">{{produto.id}}</div>
                        <div>{{produto.descricao}}</div>
                        <div>{{produto.observacao}}</div>
                        <div >
                            <button class="alterar-btn" @click="carregarProduto(produto.id)">Alterar</button>
                            <button class="delete-btn" @click="deleteProduto(produto.id)">Excluir</button>
                        </div>
                    </div> 
                    
                    <nav aria-label="...">
                        <ul class="pagination">
                            <li  @click="paginaAnterior()"  class="page-item"><a class="page-link" href="#" tabindex="-1">Anterior</a></li>
                            <li  v-for="pagina in totalPaginas" :key="pagina"    @click="calcOffSet(pagina)" class="page-item" :class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
                            <li  @click="proximaPagina()" class="page-item"><a class="page-link" href="#">Próximo</a></li>
                        </ul>
                    </nav>
                                  
                
                </div>

            </div>

        </div> 
        
        <div v-if="tOp != 'view'">
            <p> <strong>cadastro de produto</strong> </p>
            
            <form>
           
                <div class="form-row">
                    <div class="form-group col-md-12">
                       <label for="descricao">Descrição</label>
                       <input name="descricao" v-model="descricao" type="text" class="form-control" id="descricao" placeholder="Descrição">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-12">
                       <label for="observacao">Observação</label>
                       <input name="observacao" v-model="observacao" type="text" class="form-control" id="observacao" placeholder="Observação">
                    </div>
                </div>


            </form>

        </div>

    </div>
</template>

<script>

import Message from '../../Message.vue'
import {urlAPI, tipoOp} from '../../../config.js'




export default {
    name:"ProdutoListForm",
    Components:{
      Message 
    },
    data(){
        return{
            
            //Campos  
            produtos:null,
            produto_id:null,
            descricao:null,
            valor:null,
            hashnumber:null,
            idusuario:null,
            idadmin:null,
            observacao:null,
            
            
            //Variável de controle da página
            offset:0,
            limit:10,
            totalPaginas:0,
            paginaAtual:1,
            msg:null,            
            tOp:null,
            tipoAlerta:null,
            filtro:null,
            sort:null

        }
        
    },
    methods:{
       
       
       isActive(pagina){
            return pagina == this.paginaAtual ? 'active' : ''
        },

        proximaPagina(){
            if (this.paginaAtual >= this.totalPaginas){
                return
            }
            this.paginaAtual ++
            this.calcOffSet(this.paginaAtual)
        },
        
        paginaAnterior(){

            if (this.paginaAtual <= 1){
               return 
            }


            this.paginaAtual --


            this.calcOffSet(this.paginaAtual)
        },

        calcOffSet(pagina){

            this.offset = (pagina * this.limit) - this.limit
            
            this.getProdutos()

            this.paginaAtual = pagina

            console.log('pagina ',pagina)
        },

        limparCampos(){
           
           this.produto_id = null
           this.descricao = ""
           this.valor = 0
           this.observacao = ""
           


           this.hashnumber = ""
           this.idusuario = this.$store.getters.getUserID
           this.idadmin =  this.$store.getters.getIdAdmin
           

        },        
        
        
        limparMsg(){
           this.msg = null
           this.tipoAlerta = null 
        },


        cancelarProduto(){
            
            this.tOp = tipoOp.view
        },
        
        async salvarProduto(){

          let data = {
                
                id:  this.produto_id,
                descricao:  this.descricao,
                valor: this.valor,
                observacao: this.observacao,
                hashnumber:this.hashnumber,
                idusuario: this.idusuario,
                idadmin: this.idadmin

           }

           let dataJson = JSON.stringify(data)

           let endpoint;
           let methods;
           
           if (this.tOp == tipoOp.create){
               endpoint = '/prospeccao/produtos/insert'
               methods = "POST"
               delete data.id //Para inclusão no banco é necessário excluir o id  
           }else if (this.tOp == tipoOp.update){
               endpoint = '/prospeccao/produtos/update' 
               methods = "PUT"
               
           } 


           console.log(data)

            try {
                const req = await fetch(urlAPI+endpoint,{
                    method:methods,
                    headers: {"content-type":"application/json"},
                    body:dataJson 
                })
           
                const res = await req.json()                
                
                console.log('Resposta: ',res)

                dataJson = JSON.stringify(res)

                console.log(dataJson)                
                
                if (res.Result == 'OK'){

                   this.tipoAlerta = "alert-success"
                   
                   this.msg = res.Message

                    // clear message
                    setTimeout(() => this.limparMsg(), 3000)


                
                } else {

                    this.tipoAlerta = "alert-warning"

                    this.msg = res.Message
                    
                    if(dataJson.indexOf("idx_email") > 0){
                        
                        this.tipoAlerta = "alert-warning"

                        this.msg = "Email já cadastrado"
                    }                   
                    
                    // clear message
                    setTimeout(() => this.limparMsg(), 3000)


                }
                
                let result = JSON.parse(dataJson)                
                
                console.log(this.msg)               
               
               console.log(result.Result, result.Message)

               this.tOp = tipoOp.view

               this.getProdutos()

               this.limparCampos()
           
           } catch (error) {
                
                this.tipoAlerta = "alert-danger"
                this.msg = 'Ocorreu um erro ao processar informações' 

                console.log('Error: ',error);

                // clear message
                setTimeout(() => this.limparMsg(), 3000)


           }

            
            
        },

        async getProdutos(){
            console.log(urlAPI)
            
            //Ordenação
            this.sort = 'descricao'
            
            //Filtrar por uma determinada informação 
            //this.filtro = "nomeusuario = 'master'"

            //TODO: Quando realizar o GET enviar o idusuario, identificar se é colaborador, caso seja ele seja colaborador então deverá montar json no servidor somente com dados do colaborador.
            //TODO: Mudar rotina de filtrar dados do usuário colaborador logado para que seja responsabilidade do servidor com isso aumentará a segurança da informação
            //Filtrar por uma determinada informação 

            this.filtro = `idadmin = ${this.$store.getters.getIdAdmin}`

            if (this.$store.getters.getIsColaborador) {
               this.filtro = this.filtro + `and idusuario = ${this.$store.getters.getUserID}`   
            }

            try {

                 const req = await fetch(urlAPI+`/prospeccao/produtos/list/offset/${this.offset}/limit/${this.limit}/filtro/${this.filtro}/sort/${this.sort}`,{
                    method:"GET",
                    headers: {"content-type":"application/json"},
                     
                })

                const res = await req.json()
                
                this.produtos = res.Prospeccao_Produtos

                //this.totalGeralRegistro = res.totalGeralRegistro

               
               this.produtos != null ?  this.totalPaginas = Math.ceil(res.totalGeralRegistro / this.limit) : 0

                
                console.log(this.produtos) 
               
                 
                
            } catch (error) {
                console.log("erro ao listar usuários", error)
            }


        },

        async deleteProduto(id){
            
            const resultado = confirm("Deseja excluir Produto para prospectar " + "" + " ?");
            if (resultado !== true){
                return
            }

            try {

               const req = await fetch(urlAPI+`/prospeccao/produtos/delete/${id}`,{
                    method:"DELETE",
                  
                     
                })

                const res = await req.json()
                
                //this.Produtos = res 

                console.log(res)

                console.log(id)

                //Dependendo da situação o correto é excluir a informação da tela ao invés de fazer nova requisição para o servidor
                this.getProdutos()

                //Transforma JSON em String
                //let dataJson = JSON.stringify(res)
                
               //Transforma String em JSON 
               // let result = JSON.parse(dataJson)  

               this.tipoAlerta = "alert-success"
               
               this.msg=res.Message

               // clear message
               setTimeout(() => this.limparMsg(), 3000)
                
                
            } catch (error) {
                console.log(error)
            }
        },

        async carregarProduto(id){
            try{
                
                
                const req = await fetch(urlAPI+`/prospeccao/produtos/find/${id}`,{
                    method:"GET",
                    headers: {"content-type":"application/json"},
                  
                     
                })

                const res = await req.json()
                

                console.log(res)

                console.log(id)
              
                //Carrega dados
                this.produto_id = res.id
                this.descricao = res.descricao
                this.valor = res.valor
                this.observacao = res.observacao
                this.hashnumber = res.hashnumber
                this.idusuario = res.idusuario
                this.idadmin = res.idadmin

                this.tOp = tipoOp.update
                console.log('operação ',this.tOp) 
            }
            catch(error){
                console.log(error)
            }

            console.log(id)
    
            
        },

        novoProduto(){
            try{
                this.limparCampos()
                this.tOp = tipoOp.create
                console.log('operação ',this.tOp)                 
            }
            catch(error){
                console.log(error)
            }
            
        },



    },

    mounted(){        
        this.tOp = tipoOp.view

        //this.getDadosPagina(1)
        
        this.getProdutos()
    }
}
</script>

<style scoped>
 
   #list-table{
       max-width: 1200px;
       margin: 0 auto;
   }
 

 
   #head-table, #det-rows, .det-row{
        display: flex;
       flex-wrap: wrap;
   }

   #head-table{
       font-weight: bold;
       padding: 12px;
       border-bottom: 3px solid #333;
   }

   .det-row{
       width: 100%;
       padding: 12px;
       border-bottom: 1px solid #ccc;
   }

   #head-table div, .det-row div{
       width: 25%
   }

   #head-table .order-id, #det-row .order-number{
       width: 5%;
   }


 .container{
      margin: 0 auto;
  }
   
   .order-id{
       margin: 0 auto;
   }

   .order-number{
       margin: 0 auto;
   } 



   .delete-btn{
      background: #222;
      color:#fcba03;
      font-weight: bold;
      border:  2px solid #222;
      padding: 10px;
      font-size: 16px;
      margin: 0 auto;
      cursor: pointer;
      transition: .5s;
   }

   .delete-btn:hover{
       background-color: transparent;
       color:#222;
   }



   .alterar-btn{
      background: #222;
      color:#fcba03;
      font-weight: bold;
      border:  2px solid #222;
      padding: 10px;
      font-size: 16px;
      margin: 0 10px;
      cursor: pointer;
      transition: .5s;
      
   }

   .alterar-btn:hover{
       background-color: transparent;
       color:#222;
   }

   .novo-btn{
      background: #222;
      color:#fcba03;
      font-weight: bold;
      border:  2px solid #222;
      padding: 10px;
      font-size: 16px;
      margin: 0 10px auto;
      cursor: pointer;
      transition: .5s;
      min-width: 90px;
      
   }

   .novo-btn:hover{
       background-color: transparent;
       color:#222;
   }



   .painel-superior{
      
       float:right;
       
            
   } 

   .btn-custom{
       margin: 10 10 10 10;
   }


   .modal-active{
     display:block;
   }




</style>

